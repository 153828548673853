<template>
  <DetailView
    class="customer-detail"
    :title="title"
    :loading="runningAction"
    :tabs="tabs"
    @close="$emit('close', false)"
  >
    <template #actions="{ loading }">
      <v-btn
        :color="hasViolation ? 'error' : 'green'"
        class="customer-editor-save-btn"
        data-test-id="customerDetailSaveBtn"
        :outlined="!isCreate && !hasChanged"
        :disabled="loading"
        @click="upsertCustomer"
      >
        <ViolationAlert
          v-if="hasViolation"
          :violation="violation"
          color="white"
          data-test-id="customerViolationAlert"
          alignment="left"
        />
        {{ isCreate ? "Create" : "Save" }}
      </v-btn>
      <v-btn
        v-if="!isCreate && customerType === 'B2C_CUSTOMER'"
        color="red"
        class="customer-editor-delete-btn"
        outlined
        data-test-id="customerDetailDeleteBtn"
        :disabled="loading"
        @click="deleteCustomer"
      >
        Delete
      </v-btn>
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.overview="{ loading }">
      <v-form
        @submit.prevent
        ref="customerForm"
        class="customer-form"
        data-test-id="customerForm"
      >
        <!-- COMMON ATTRIBUTES -->
        <v-card
          flat
          class="customer-section customer-information"
          id="customerinfo"
        >
          <v-card-title>
            <span>Customer Information</span>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!isCreate"
              outlined
              data-test-id="exportGdprDataBtn"
              title="Download GDPR compliant data"
              :disabled="runningAction"
              @click="exportCustomerData"
            >
              <v-icon left>mdi-file-export</v-icon>
              Export
            </v-btn>
            <v-btn
              v-if="customer.id"
              outlined
              class="ml-2"
              data-test-id="openKeycloakBtn"
              :disabled="runningAction"
              @click="openKeycloak()"
            >
              <v-icon left>mdi-open-in-new</v-icon>
              Open in Keycloak
            </v-btn>
          </v-card-title>

          <v-container fluid>
            <v-row dense justify="center">
              <v-col>
                <v-text-field
                  v-if="!isCreate"
                  v-model="customer.id"
                  dense
                  outlined
                  class="customer-form-id"
                  disabled
                  readonly
                  label="ID*"
                  :rules="[ruleSet.required]"
                  data-test-id="customerId"
                />
                <v-select
                  v-model="customer.type"
                  dense
                  outlined
                  disabled
                  class="customer-form-type"
                  label="Customer Type*"
                  :items="customerTypes"
                  :rules="[ruleSet.required]"
                  data-test-id="customerType"
                />
                <v-text-field
                  v-model="customer.erpId"
                  dense
                  outlined
                  class="customer-form-erp-id"
                  label="ERP ID"
                  :disabled="runningAction"
                  data-test-id="customerErpId"
                />
                <v-text-field
                  dense
                  outlined
                  class="customer-form-firstname"
                  v-model="customer.firstname"
                  :disabled="runningAction"
                  label="First Name*"
                  :rules="[ruleSet.required]"
                  data-test-id="customerFirstName"
                />
                <v-text-field
                  dense
                  outlined
                  class="customer-form-lastname"
                  v-model="customer.lastname"
                  :disabled="runningAction"
                  label="Last Name*"
                  :rules="[ruleSet.required]"
                  data-test-id="customerLastName"
                />
                <v-text-field
                  dense
                  outlined
                  class="customer-form-email"
                  v-model="customer.email"
                  :disabled="runningAction"
                  label="E-Mail*"
                  :rules="[ruleSet.required, ruleSet.email]"
                  data-test-id="customerEmail"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-select
                  dense
                  outlined
                  class="customer-form-gender"
                  v-model="customer.gender"
                  :disabled="runningAction"
                  label="Gender"
                  :items="genders"
                  data-test-id="customerGender"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="customer.birthdate"
                  dense
                  outlined
                  label="Date of Birth"
                  class="customer-form-birthdate-input"
                  type="date"
                  :disabled="runningAction"
                  :max="today"
                  :rules="[ruleSet.beforeToday]"
                  data-test-id="customerBirthdate"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-select
                  dense
                  outlined
                  class="customer-form-locale"
                  v-model="customer.locale"
                  :disabled="runningAction"
                  label="Locale"
                  data-test-id="customerLocale"
                  :items="supportedLocales"
                  item-text="text"
                  item-value="value"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  class="customer-form-password"
                  v-if="isCreate"
                  v-model="customer.password"
                  :disabled="runningAction"
                  label="Password*"
                  :rules="[ruleSet.required]"
                  data-test-id="customerPassword"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <!-- ADDITIONAL ATTRIBUTES -->
        <v-card
          v-if="!isCreate"
          flat
          class="customer-section customer-custom-attributes"
          id="custom"
          data-test-id="customerAdditionalAttrs"
        >
          <v-card-title>
            Custom Attributes
            <v-spacer></v-spacer>
            <v-btn
              v-if="!isCreate"
              :disabled="runningAction"
              color="primary"
              data-test-id="addAttributeBtn"
              @click="addCustomAttribute()"
            >
              New Attribute
            </v-btn>
          </v-card-title>
          <v-container fluid>
            <template v-if="customAttributes">
              <v-row
                v-for="(attr, name) in customAttributes"
                :key="name"
                justify="center"
              >
                <v-text-field
                  v-model="customer.customAttributes[name]"
                  dense
                  outlined
                  :data-test-id="'attribute_' + name"
                  :disabled="runningAction"
                  :label="name"
                />
                <v-btn
                  icon
                  color="red"
                  class="ml-2"
                  title="Remove attribute"
                  :disabled="runningAction"
                  :data-test-id="'attribute_remove_' + name"
                  @click="removeCustomAttribute(name)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </template>
            <v-row justify="center" v-else>
              <v-card-text>No additional data found</v-card-text>
            </v-row>
          </v-container>
        </v-card>
      </v-form>

      <!-- ADDRESSES -->
      <AddressOverview
        v-if="customer.id"
        :customer="customer"
        :key="customer.id"
        :disabled="runningAction"
        :domain="selectedDomain"
        data-test-id="customerAddresses"
        class="mb-3"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.orders="{ loading }">
      <v-container fluid class="customer-order-container">
        <OrderOverview
          open-details-in-tab
          data-test-id="customerOrders"
          :customer="customer.id"
        />
      </v-container>
    </template>
  </DetailView>
</template>

<script>
import AddressOverview from "./address/AddressOverview";
import OrderOverview from "components/orders/OrderOverview";
import DetailView from "components/common/templates/DetailView";
import ViolationAlert from "components/common/display-helpers/ViolationAlert";
import CustomerEnum from "mixins/enum/CustomerEnum";
import keycloakMixin from "mixins/keycloak-mixin";

export default {
  mixins: [CustomerEnum, keycloakMixin],

  props: {
    previewData: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },

  inject: ["getCustomerDetailsHeight"],

  provide() {
    return {
      //provide page height, so that the order overview
      //calculates the correct table height
      getPageHeight: () => {
        return this.getCustomerDetailsHeight - 120;
      },
    };
  },

  components: {
    OrderOverview,
    AddressOverview,
    DetailView,
    ViolationAlert,
  },

  data() {
    return {
      customer: this.$cloneObject(this.previewData),
      loading: false,
      selectedTab: null,
      runningAction: false,
      hasChanged: false,
      savedCustomer: null,
      violation: null,
    };
  },

  mounted() {
    if (!this.isCreate) {
      this.loadCustomer();
    } else {
      this.$nextTick(() => {
        this.$set(this.customer, "type", "B2C_CUSTOMER");
        this.savedCustomer = this.$cloneObject(this.customer);
      });
    }
  },

  watch: {
    "previewData.id": function () {
      //another user has been selected
      this.customer = this.previewData;
    },

    customer: {
      handler: function (customer) {
        this.hasChanged = !this.$isEqual(customer, this.savedCustomer);
      },
      deep: true,
    },

    savedCustomer: {
      handler: function (savedCustomer) {
        this.hasChanged = !this.$isEqual(this.customer, savedCustomer);
      },
      deep: true,
    },

    hasChanged(hasChanged) {
      this.$set(this.$route.params, "unsavedChanges", hasChanged);
    },
  },

  methods: {
    closeEditor() {
      this.$emit("close");
    },

    async loadCustomer() {
      this.runningAction = true;
      try {
        const customer = await this.$store.$coreApi.coreCustomerApi.getCustomer(
          this.selectedDomain,
          this.customer.id
        );

        this.customer = customer ?? this.customer;
        this.savedCustomer = this.$cloneObject(this.customer);
      } finally {
        this.runningAction = false;
      }
    },

    openKeycloak() {
      let realm = this.selectedDomain;

      if (
        this.customer.type == "B2B_RESELLER" ||
        this.customer.type == "B2B_RESELLER_USER"
      ) {
        // for resellers and reseller users the keycloak realm
        // must be extracted from the customer attributes
        realm = this.customer.customAttributes["b2b.keycloakRealm"];
      }

      this.openUserInKeycloak(this.customer.id, realm);
    },

    async exportCustomerData() {
      //retrieve gdpr compliant customer data as JSON, isCreate a text file and download it
      try {
        this.runningAction = true;
        const id = this.customer.id;
        const customerData =
          await this.$store.$coreApi.coreCustomerApi.getCustomerGdpr(
            this.selectedDomain,
            id
          );

        if (!customerData) return;

        const formattedData = JSON.stringify(customerData, null, 4);
        const href =
          "data:text/plain;charset=utf-8," + encodeURIComponent(formattedData);

        let element = document.createElement("a");
        element.setAttribute("href", href);
        element.setAttribute("download", "customer_" + id + ".txt");
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } finally {
        this.runningAction = false;
      }
    },

    async upsertCustomer() {
      this.violation = null;
      if (!this.validate()) return;
      //if a custom attribute is empty, remove it
      const customAttributes = this.customer?.customAttributes?.attr;
      if (customAttributes) {
        for (const property in customAttributes) {
          if (!customAttributes[property]) {
            this.$delete(customAttributes, property);
          }
        }
      }

      this.runningAction = true;
      try {
        if (this.isCreate) {
          const res = await this.$store.$coreApi.coreCustomerApi.createCustomer(
            this.selectedDomain,
            this.customer
          );
          if (!res?.ok) return;
          this.customer = await res.json();
          await this.loadCustomer();
          await this.$nextTick(() => {
            this.$router.replace({
              name: "customerDetail",
              params: { customer: this.customer.id },
              query: this.$route.query,
            });
          });
        } else {
          const res = await this.$store.$coreApi.coreCustomerApi.upsertCustomer(
            this.selectedDomain,
            this.customer
          );
          if (!res?.ok) return;
          this.savedCustomer = this.$cloneObject(this.customer);
        }
        this.$emit("reload");
      } finally {
        this.runningAction = false;
      }
    },

    async deleteCustomer() {
      if (
        await this.$confirm(
          "Delete customer?",
          "Are you sure you want to delete customer " +
            this.customer.firstname +
            " " +
            this.customer.lastname +
            "? This cannot be undone."
        )
      ) {
        this.runningAction = true;
        try {
          const res = await this.$store.$coreApi.coreCustomerApi.deleteCustomer(
            this.selectedDomain,
            this.customer
          );
          if (!res?.ok) return;
          this.$emit("deleted");
        } finally {
          this.runningAction = false;
        }
      }
    },

    async addCustomAttribute() {
      let customAttributes = this.customer?.customAttributes ?? {};
      const name = await this.$prompt("Name of custom attribute", {
        rules: [
          (val) => !!val || "Name is required",
          (val) => {
            return (
              customAttributes?.[val] === undefined ||
              "Attribute does already exist"
            );
          },
        ],
      });

      if (!name) return;
      this.$set(customAttributes, name, "");
      this.$set(
        this.customer,
        "customAttributes",
        Object.assign({}, customAttributes)
      );
    },

    removeCustomAttribute(name) {
      this.$delete(this.customer.customAttributes, name);
      if (Object.keys(this.customer.customAttributes).length === 0) {
        this.$delete(this.customer, "customAttributes");
      }
    },

    validate() {
      const form = this.$refs.customerForm;
      if (!this.$validateVForm(form)) {
        this.violation = {
          message: "At least one input is invalid, please check",
        };
        return false;
      }
      this.violation = null;
      return true;
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    supportedLocales() {
      const languageNames = new Intl.DisplayNames(["en"], { type: "language" });
      const supportedLocales = this.$store.state.localization.supportedLocales;
      return supportedLocales.map((locale) => {
        return {
          text: languageNames.of(locale),
          value: locale,
        };
      });
    },

    title() {
      if (this.isCreate) return "New customer";
      if (this.savedCustomer) {
        return this.savedCustomer.firstname + " " + this.savedCustomer.lastname;
      }
      return this.previewData.firstname + " " + this.previewData.lastname;
    },

    genders() {
      return [
        { text: "", value: null },
        { text: "MALE", value: "MALE" },
        { text: "FEMALE", value: "FEMALE" },
      ];
    },

    tabs() {
      const tabs = [
        { text: "Overview", value: "overview" },
        { text: "Orders", value: "orders" },
      ];

      if (this.isCreate) {
        return tabs.filter(({ value }) => value === "overview");
      }
      return tabs;
    },

    customAttributes() {
      return this.customer?.customAttributes;
    },

    isCreate() {
      return !this.customer?.id;
    },

    today() {
      return new Date().toISOString().split("T")[0];
    },

    ruleSet() {
      return {
        required: (val) => !!val || "Value is required",
        beforeToday: (val) => {
          if (!val) return true;
          const birthdate = new Date(val);
          const today = new Date(this.today);
          return (
            this.$getDayDiff(birthdate, today) >= 0 ||
            "Date of birth must be in the past"
          );
        },
        email: (val) => {
          const emailRegex = new RegExp(
            "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$"
          );
          return (
            emailRegex.test(val) || "Email address must have a valid format"
          );
        },
      };
    },

    customerType() {
      return this.savedCustomer?.type ?? this.previewData?.type;
    },

    hasViolation() {
      return !!this.violation;
    },
  },
};
</script>

<style scoped>
.customer-section {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
}

.customer-addresses,
.customer-custom-attributes {
  border-top: 1px solid lightgrey;
}

.customer-form {
  padding: 0px;
}

.customer-editor {
  padding: 0px;
  overflow-y: hidden;
  align-items: flex-start;
  align-content: flex-start;
}

.customer-form-container {
  align-items: flex-start;
  align-content: flex-start;
  margin-top: 8px;
  padding: 0px;
  height: calc(100% - 120px);
  overflow-y: scroll;
}

.customer-form-gender,
.customer-form-birthdate-input {
  margin-right: 10px;
}

.customer-editor-section-picker {
  max-width: 200px;
  margin-right: 5px;
}

.customer-order-container {
  padding: 0;
}

.customer-order-container .orders-main-container {
  max-height: calc(100% - 120px);
}
</style>