<template>
  <v-card flat class="customer-section customer-addresses" id="addresses">
    <v-card-title>
      <v-icon left>mdi-map</v-icon>
      <span>Addresses</span>
      <v-spacer></v-spacer>
      <v-btn
        @click="addAddress"
        :disabled="
          disabled || runningAction || (onlyOne && addresses.length > 0)
        "
        color="primary"
        data-test-id="addAddressBtn"
      >
        New Address
      </v-btn>
    </v-card-title>
    <v-data-table
      dense
      sort-by="preferred"
      sort-desc
      hide-default-footer
      :items="addresses"
      :items-per-page="-1"
      :headers="addressHeaders"
      :loading="runningAction"
      class="customer-addresses-table"
    >
      <!-- eslint-disable-next-line -->
      <template #item.preferred="{ item }">
        <v-icon small v-if="item.preferred"> mdi-star </v-icon>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.actions="{ item }">
        <div class="d-flex flex-row">
          <v-btn
            small
            icon
            :disabled="disabled || runningAction"
            :data-test-id="'address_' + item.id + '_editBtn'"
            @click="editAddress(item)"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            small
            icon
            :disabled="disabled || runningAction"
            :data-test-id="'address_' + item.id + '_deleteBtn'"
            @click="deleteAddress(item)"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <!-- UPSERT ADDRESS DIALOG -->
    <v-dialog v-model="addressDialog" max-width="600px">
      <Address
        v-if="selectedAddress"
        :customer="customer"
        :title="selectedAddress.id ? 'Edit address' : 'Add new address'"
        :allowed-address-types="allowedAddressTypes"
        :value="selectedAddress"
        :domain="domain"
        :key="selectedAddress.id"
        @close="closeAddressDialog"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import CustomerEnum from "../../../mixins/enum/CustomerEnum";
import Address from "./Address";

export default {
  mixins: [CustomerEnum],

  props: {
    customer: {
      type: Object,
      required: true,
    },

    domain: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    allowedAddressTypes: {
      type: Array,
      required: false,
    },

    onlyOne: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    Address,
  },

  data() {
    return {
      selectedAddress: null,
      addressDialog: false,
      addresses: [],
      loading: false,
      runningAction: false,
    };
  },

  mounted() {
    this.loadAddresses();
  },

  methods: {
    async loadAddresses() {
      if (this.customer.id) {
        this.runningAction = true;
        try {
          //load the customers addresses and categorize it into billing and shipping address
          this.addresses =
            await this.$store.$coreApi.coreCustomerApi.getAddresses(
              this.domain,
              this.customer.id
            );

          this.$emit("addresses", this.addresses);
        } finally {
          this.runningAction = false;
        }
      }
    },

    addAddress() {
      this.addressDialog = true;
      this.selectedAddress = {};
      if (this.addressTypes.length === 1) {
        this.selectedAddress.type = this.addressTypes[0].value;
      }
    },

    editAddress(address) {
      this.addressDialog = true;
      this.selectedAddress = this.$cloneObject(address);
    },

    async deleteAddress(address) {
      if (
        await this.$confirm(
          "Delete address?",
          "Are you sure you want to delete this address? This cannot be undone."
        )
      ) {
        this.runningAction = true;
        try {
          const res = await this.$store.$coreApi.coreCustomerApi.deleteAddress(
            this.domain,
            this.customer.id,
            address.id
          );

          if (!res?.ok) return;
        } finally {
          this.runningAction = false;
        }

        this.loadAddresses();
      }
    },

    closeAddressDialog(reload) {
      this.addressDialog = false;
      this.selectedAddress = null;
      if (reload) this.loadAddresses();
    },
  },

  computed: {
    addressHeaders() {
      return [
        { text: "", value: "preferred" },
        { text: "Type", value: "type" },
        { text: "First Name", value: "firstname" },
        { text: "Last Name", value: "lastname" },
        { text: "Street", value: "street" },
        { text: "City", value: "city" },
        { text: "Post code", value: "postcode" },
        { text: "Country", value: "countryCode" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
};
</script>
