var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MasterDetail',{attrs:{"data-test-id":"customerPage","detail-open":!!_vm.selectedCustomer},on:{"table:resize":function($event){_vm.tableWidth = $event}},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('Toolbar',{key:_vm.possibleFilters.length,staticClass:"customer-table-toolbar",attrs:{"search-props":{
        disableFullTextSearch: true,
        possibleFilters: _vm.possibleFilters,
      }},on:{"update-filters":function (updated) { return (_vm.filters = updated); }},scopedSlots:_vm._u([{key:"extended",fn:function(){return [_c('div',{staticClass:"extended-toolbar"},[_c('v-select',{staticClass:"customer-limit-select",attrs:{"dense":"","hide-details":"","items":_vm.pageLimits,"disabled":_vm.runningAction,"data-test-id":"pageLimitSelector"},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}}),_vm._v(" rows "),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),(_vm.totalPages > 0)?_c('PaginationComponent',{attrs:{"totalPages":_vm.totalPages,"disabled":_vm.runningAction},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),(_vm.totalPages > 0)?_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.total)+" total")]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","data-test-id":"newCustomerBtn","title":"Create a new customer","fab":_vm.showMinified,"small":_vm.showMinified,"disabled":_vm.runningAction || _vm.isModeCreate},on:{"click":function($event){return _vm.createCustomer()}}},[(_vm.showMinified)?_c('v-icon',[_vm._v("mdi-plus")]):_c('div',[_vm._v("New Customer")])],1)],1)]},proxy:true}]),model:{value:(_vm.filterQuery),callback:function ($$v) {_vm.filterQuery=$$v},expression:"filterQuery"}})]},proxy:true},{key:"table",fn:function(){return [_c('v-data-table',{staticClass:"customer-table",attrs:{"dense":"","disable-pagination":"","hide-default-footer":"","fixed-header":"","single-select":"","multi-sort":"","items":_vm.customers,"headers":_vm.headers,"loading":_vm.runningAction,"height":_vm.tableHeight,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',{class:{
            selected: _vm.selectedCustomer && item.id === _vm.selectedCustomer.id,
          },attrs:{"data-test-id":'customer_' + item.id},on:{"click":function($event){!_vm.runningAction && _vm.editCustomer(item)}}},[_c('td',{staticClass:"customer-first-name",attrs:{"data-test-id":'customer_firstname_' + item.id}},[_vm._v(" "+_vm._s(item.firstname)+" ")]),_c('td',{staticClass:"customer-last-name",attrs:{"data-test-id":'customer_lastname_' + item.id}},[_vm._v(" "+_vm._s(item.lastname)+" ")]),_c('td',{staticClass:"customer-email",attrs:{"data-test-id":'customer_email_' + item.id}},[_vm._v(" "+_vm._s(item.email)+" ")]),_c('td',{staticClass:"customer-gender",attrs:{"data-test-id":'customer_gender_' + item.id}},[_vm._v(" "+_vm._s(item.gender)+" ")]),_c('td',{staticClass:"customer-birthdate",attrs:{"data-test-id":'customer_birthdate_' + item.id}},[_vm._v(" "+_vm._s(item.birthdate ? _vm.$getLocalizedDate(item.birthdate, { year: "numeric", month: "numeric", day: "numeric", }) : "")+" ")]),_c('td',{staticClass:"customer-type",attrs:{"data-test-id":'customer_type_' + item.id}},[_vm._v(" "+_vm._s(item.type)+" ")])])]}}])})]},proxy:true},{key:"detail",fn:function(){return [(_vm.selectedCustomer)?_c('CustomerDetails',{key:_vm.selectedCustomer.id,style:({
        height: _vm.detailHeight + 'px',
      }),attrs:{"data-test-id":"customerDetail","preview-data":_vm.selectedCustomer},on:{"close":_vm.closeEditor,"reload":_vm.loadCustomers,"deleted":function($event){return _vm.closeEditor(true)}}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }