export default {

    computed: {

        customerTypes() {
            return [
                { text: "B2C CUSTOMER", value: "B2C_CUSTOMER" },
                { text: "B2B RESELLER", value: "B2B_RESELLER" },
                { text: "B2B RESELLER USER", value: "B2B_RESELLER_USER" },
            ];
        },

        addressTypes() {
            const allTypes = [
                { text: "Shipping Address", value: "SHIPPING_ADDRESS" },
                { text: "Billing Address", value: "BILLING_ADDRESS" },
            ];

            if (this.allowedAddressTypes) {
                return allTypes.filter(({ value }) => this.allowedAddressTypes.some((type) => type === value));
            }
            return allTypes;
        },

    }

}