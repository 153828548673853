<template>
  <v-select
    v-model="country"
    :items="countries"
    :outlined="outlined"
    :label="label"
    :dense="dense"
    :rules="rules"
    :disabled="runningAction || disabled"
    :loading="runningAction"
    item-value="code"
    item-text="name"
  >
    <template #selection="{ item }">
      <div class="d-flex flex-row">
        <div class="mr-2">{{ item.emoji }}</div>
        <div class="text-truncate" :title="item.name">
          {{ item.name }}
        </div>
      </div>
    </template>
    <template #item="{ item }">
      <div
        class="d-flex flex-row flex-grow-1"
        :data-test-id="'country_' + item.code"
      >
        <div class="mr-2">{{ item.emoji }}</div>
        <div>{{ item.name }}</div>
      </div>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },

    label: {
      type: String,
      required: false,
    },

    outlined: {
      type: Boolean,
      required: false,
      default: true,
    },

    dense: {
      type: Boolean,
      required: false,
      default: true,
    },

    rules: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
      validator: (rules) => {
        return rules.every((rule) => typeof rule === "function");
      },
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      country: this.value,
      countries: this.$getCountries(),
      favouriteCountries: [],
      runningAction: false,
    };
  },

  watch: {
    value(value) {
      this.country = value;
    },

    country(code) {
      this.$emit("input", code);
    },
  },

  async mounted() {
    try {
      this.runningAction = true;
      const merged =
        await this.$store.$coreApi.coreConfigurationApi.getMergedData(
          this.selectedDomain,
          "Configuration",
          "core.common"
        );
      const config = merged?.data;
      const favouriteCountriesConfig = config?.favouriteCountries;
      if (!favouriteCountriesConfig || favouriteCountriesConfig?.length === 0)
        return;

      const favouriteCountries = favouriteCountriesConfig
        .reduce((countryList, country) => {
          const countryProps = this.countries.find(
            ({ code }) => country.code === code
          );
          const isDuplicate = countryList.some(
            ({ code }) => country.code === code
          );
          //remove non-existing and duplicate countries
          if (countryProps && !isDuplicate) {
            countryList.push({
              ...country,
              ...countryProps,
              isFavourite: true,
            });
          }
          return countryList;
        }, [])
        .sort((country1, country2) => {
          return country1.position - country2.position;
        });

      const otherCountries = this.countries.filter((country) => {
        return favouriteCountries.some(({ code }) => country.code !== code);
      });
      this.countries = favouriteCountries
        .concat([{ divider: true }])
        .concat(otherCountries);
    } finally {
      this.runningAction = false;
    }
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },
  },
};
</script>