

export default {
    methods: {
        openUserInKeycloak(userId, realm) {
            const keycloakDomain = this.$keycloak.authServerUrl
            const keycloakRealm = realm ?? this.$keycloak.realm;
            const url =
                keycloakDomain +
                "/admin/master/console/#/" +
                keycloakRealm +
                "/users/" +
                userId +
                "/settings";

            window.open(url, "_blank", "noopener");
        }
    }
}