<template>
  <DialogForm
    :loading="runningAction"
    :title="title"
    save-btn-test-id="addressSaveBtn"
    close-btn-test-id="addressCloseBtn"
    data-test-id="addressDialog"
    @close="$emit('close', false)"
    @save="upsertAddress"
  >
    <template #form="{ loading }">
      <v-form @submit.prevent ref="addressForm" class="customer-address-form">
        <v-container>
          <v-row dense align="center">
            <v-col>
              <v-select
                dense
                v-model="address.type"
                label="AddressType*"
                class="customer-address-form-type"
                :items="addressTypes"
                outlined
                :rules="[ruleSet.required]"
                :disabled="loading"
                data-test-id="addressType"
              />
            </v-col>
            <v-col>
              <v-checkbox
                dense
                v-if="address.type"
                v-model="address.preferred"
                class="customer-address-form-preferred"
                :disabled="loading"
                data-test-id="addressPreferred"
              >
                <template #label class="text-truncate">
                  <div class="text-truncate">Preferred address</div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" right>
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <div class="help-tooltip">
                      The preferred address property is set by default to the
                      first address added.
                    </div>
                  </v-tooltip>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                dense
                v-model="address.title"
                class="customer-address-form-title"
                label="Title"
                outlined
                :disabled="loading"
                data-test-id="addressTitle"
              />
            </v-col>
            <v-col>
              <v-text-field
                dense
                v-model="address.firstname"
                label="First Name*"
                class="customer-address-form-firstname"
                outlined
                :rules="[ruleSet.required]"
                :disabled="loading"
                data-test-id="addressFirstName"
              />
            </v-col>
            <v-col>
              <v-text-field
                dense
                v-model="address.lastname"
                label="Last Name*"
                class="customer-address-form-lastname"
                outlined
                :rules="[ruleSet.required]"
                :disabled="loading"
                data-test-id="addressLastName"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                dense
                v-model="address.email"
                label="E-Mail"
                class="customer-address-form-email"
                outlined
                :disabled="loading"
                :rules="[ruleSet.email]"
                data-test-id="addressEmail"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                dense
                v-model="address.street"
                label="Street*"
                class="customer-address-form-street"
                outlined
                :rules="[ruleSet.required]"
                :disabled="loading"
                data-test-id="addressStreet"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                dense
                v-model="address.city"
                label="City*"
                class="customer-address-form-city"
                outlined
                :rules="[ruleSet.required]"
                :disabled="loading"
                data-test-id="addressCity"
              />
            </v-col>
            <v-col>
              <v-text-field
                dense
                v-model="address.postcode"
                label="Postcode*"
                class="customer-address-form-postcode"
                outlined
                :rules="[ruleSet.required]"
                :disabled="loading"
                data-test-id="addressPostcode"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <CountrySelectWithFavourites
                v-model="address.countryCode"
                label="Country*"
                class="customer-address-form-country"
                outlined
                :rules="[ruleSet.required]"
                :disabled="loading"
                data-test-id="addressCountry"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                dense
                v-model="address.phone"
                label="Phone"
                class="customer-address-form-phone"
                outlined
                :disabled="loading"
                data-test-id="addressPhone"
              />
            </v-col>
            <v-col>
              <v-text-field
                dense
                v-model="address.mobile"
                label="Mobile"
                class="customer-address-form-mobile"
                outlined
                :disabled="loading"
                data-test-id="addressMobile"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                dense
                v-model="address.company"
                label="Company"
                class="customer-address-form-company"
                outlined
                :disabled="loading"
                data-test-id="addressCompany"
              />
            </v-col>
            <v-col>
              <v-text-field
                dense
                v-model="address.department"
                label="Department"
                class="customer-address-form-dept"
                outlined
                :disabled="loading"
                data-test-id="addressDepartment"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-textarea
                dense
                :rows="3"
                v-model="address.additionalAddressInfo"
                label="Additional Address Info"
                class="customer-address-form-additional"
                outlined
                :disabled="loading"
                data-test-id="addressAdditionalInfo"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </DialogForm>
</template>
<script>
import DialogForm from "../../common/templates/DialogForm";
import CustomerEnum from "../../../mixins/enum/CustomerEnum";
import CountrySelectWithFavourites from "../../common/inputs/CountrySelectWithFavourites";
import validationMixin from "../../../mixins/field-rule-validation";

export default {
  mixins: [CustomerEnum, validationMixin],

  components: {
    DialogForm,
    CountrySelectWithFavourites,
  },

  props: {
    customer: {
      type: Object,
      required: true,
    },

    domain: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: "Edit address",
    },

    value: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    allowedAddressTypes: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      runningAction: false,
      address: this.$cloneObject(this.value),
      inputRules: [(v) => !!v || "Value is required"],
    };
  },

  watch: {
    value: {
      handler: function (value) {
        this.address = this.$cloneObject(value);
      },
    },
  },

  methods: {
    async upsertAddress() {
      this.runningAction = true;
      try {
        if (this.$refs.addressForm.validate()) {
          let address = this.address;
          const isNew = !address.id;
          if (isNew) {
            address.id = this.$uuid.v4();
          }

          const res = await this.$store.$coreApi.coreCustomerApi.upsertAddress(
            this.domain,
            this.customer.id,
            address,
            {
              successMsg: isNew ? "Address created" : "Address updated",
            }
          );

          if (!res?.ok) return;

          this.$emit("close", true);
        }
      } finally {
        this.runningAction = false;
      }
    },
  },
};
</script>

<style scoped>
.customer-address-form-preferred {
  margin: 0 0 12px 0;
}
</style>