<template>
  <MasterDetail
    data-test-id="customerPage"
    :detail-open="!!selectedCustomer"
    @table:resize="tableWidth = $event"
  >
    <template #toolbar>
      <Toolbar
        v-model="filterQuery"
        class="customer-table-toolbar"
        :key="possibleFilters.length"
        :search-props="{
          disableFullTextSearch: true,
          possibleFilters,
        }"
        @update-filters="(updated) => (filters = updated)"
      >
        <template #extended>
          <div class="extended-toolbar">
            <v-select
              class="customer-limit-select"
              v-model="limit"
              dense
              hide-details
              :items="pageLimits"
              :disabled="runningAction"
              data-test-id="pageLimitSelector"
            />
            rows
            <v-divider class="mx-4" vertical></v-divider>

            <!-- CUSTOM PAGINATION COMPONENT -->
            <PaginationComponent
              v-if="totalPages > 0"
              v-model="currentPage"
              :totalPages="totalPages"
              :disabled="runningAction"
            />
            <v-divider class="mx-4" v-if="totalPages > 0" vertical></v-divider>
            <span>{{ total }} total</span>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="ml-3"
              data-test-id="newCustomerBtn"
              title="Create a new customer"
              :fab="showMinified"
              :small="showMinified"
              :disabled="runningAction || isModeCreate"
              @click="createCustomer()"
            >
              <v-icon v-if="showMinified">mdi-plus</v-icon>
              <div v-else>New Customer</div>
            </v-btn>
          </div>
        </template>
      </Toolbar>
    </template>

    <template #table>
      <!-- CUSTOMER TABLE -->
      <v-data-table
        dense
        disable-pagination
        hide-default-footer
        fixed-header
        single-select
        multi-sort
        class="customer-table"
        :items="customers"
        :headers="headers"
        :loading="runningAction"
        :height="tableHeight"
        :options.sync="options"
      >
        <!-- TABLE ROWS -->
        <template v-slot:item="{ item }">
          <tr
            :class="{
              selected: selectedCustomer && item.id === selectedCustomer.id,
            }"
            :data-test-id="'customer_' + item.id"
            @click="!runningAction && editCustomer(item)"
          >
            <td
              class="customer-first-name"
              :data-test-id="'customer_firstname_' + item.id"
            >
              {{ item.firstname }}
            </td>
            <td
              class="customer-last-name"
              :data-test-id="'customer_lastname_' + item.id"
            >
              {{ item.lastname }}
            </td>
            <td
              class="customer-email"
              :data-test-id="'customer_email_' + item.id"
            >
              {{ item.email }}
            </td>
            <td
              class="customer-gender"
              :data-test-id="'customer_gender_' + item.id"
            >
              {{ item.gender }}
            </td>
            <td
              class="customer-birthdate"
              :data-test-id="'customer_birthdate_' + item.id"
            >
              {{
                item.birthdate
                  ? $getLocalizedDate(item.birthdate, {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })
                  : ""
              }}
            </td>
            <td
              class="customer-type"
              :data-test-id="'customer_type_' + item.id"
            >
              {{ item.type }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
    <template #detail>
      <!-- CUSTOMER EDITOR -->
      <CustomerDetails
        v-if="selectedCustomer"
        data-test-id="customerDetail"
        :preview-data="selectedCustomer"
        :key="selectedCustomer.id"
        :style="{
          height: detailHeight + 'px',
        }"
        @close="closeEditor"
        @reload="loadCustomers"
        @deleted="closeEditor(true)"
      />
    </template>
  </MasterDetail>
</template>

<script>
import CustomerDetails from "./CustomerDetails.vue";
import PaginationComponent from "../PaginationComponent.vue";
import Toolbar from "../common/templates/Toolbar";
import CustomerEnum from "../../mixins/enum/CustomerEnum";
import MasterDetail from "../common/templates/MasterDetail.vue";
import mainOverviewMixin from "../../mixins/main-overview-mixin";
import sortQueryMixin from "mixins/sort-query-mixin";

export default {
  mixins: [CustomerEnum, mainOverviewMixin, sortQueryMixin],

  provide() {
    return {
      //provide page height, so that the customerDetail
      //calculates the correct height
      getCustomerDetailsHeight: () => {
        return this.tableHeight;
      },
    };
  },

  components: {
    CustomerDetails,
    PaginationComponent,
    Toolbar,
    MasterDetail,
  },

  data() {
    return {
      currentPage: 1,
      customers: [],
      total: 0,
      filters: {},
      runningAction: false,
      limit: 20,
      selectedCustomer: null,
      filterQuery: {},
      options: {
        sortBy: ["lastname", "firstname"],
        sortDesc: [false, false],
      },
      loadCustomerController: null,
      tableWidth: null,
    };
  },

  async created() {
    await this.init();
  },

  watch: {
    filterQuery: {
      handler: async function (filterQuery) {
        let query = Object.assign({}, this.$route.query);
        const oldFilterQuery = query.filter;
        //remove filter query if the query is faulty
        if (Object.keys(filterQuery).length > 0) {
          let copy = this.$cloneObject(filterQuery);
          if (this.$isPlainObject(copy)) {
            copy = JSON.stringify(copy);
          }
          query.filter = this.$urlEncode(copy);
        } else {
          delete query.filter;
        }
        await this.$router.push({ ...this.$route, query });
        if (oldFilterQuery !== query.filter) this.loadCustomers();
      },
      deep: true,
    },

    async currentPage() {
      //reload customers if the current page changes
      if (this.currentPage < 1) this.currentPage = 1;

      if (this.currentPage > 0 && this.currentPage <= this.totalPages) {
        await this.loadCustomers();
        await this.$router.push({
          query: Object.assign({}, this.$route.query, {
            page: this.currentPage,
          }),
        });
      }
    },

    totalPages() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
      }
    },

    async limit(limit) {
      await this.$router.push({
        ...this.$route,
        query: Object.assign({}, this.$route.query, { limit }),
      });

      if (this.totalPages === 0) return;

      //changed the limit of entries on a page, therefore recalculate current pages
      if (this.totalPages > 0 && this.currentPage > this.totalPages) {
        //change of current page triggers a reload
        this.currentPage = this.totalPages;
      } else {
        //current page did not change, so reload customers manually
        await this.loadCustomers();
      }
    },

    async options(options, old) {
      if (
        this.$isEqual(options.sortBy, old.sortBy) &&
        this.$isEqual(options.sortDesc, old.sortDesc)
      ) {
        //If sortBy and sortDesc did not change, do not update the query
        return;
      }
      let query = Object.assign({}, this.$route.query);
      const sort = this.parseOptionsToSortQuery(options);
      if (!sort) this.$delete(query, "sort");
      else this.$set(query, "sort", sort);
      await this.$router.push({ ...this.$route, query });
      await this.loadCustomers();
    },
  },

  methods: {
    async init() {
      //If the code is changed here, check in Product- and OrderOverview if it also needs changing there

      //initialize the component with the route parameters
      const namedRoute = this.$route.name;
      const routeQuery = this.$route.query;

      //get the query parameters and update the according variables
      let page = routeQuery.page;
      let limit = routeQuery.limit;
      let filter = routeQuery.filter;
      let sort = routeQuery.sort;

      let pageChange = false;

      if (page) {
        page = parseInt(page, 10);
        if (Number.isNaN(page)) page = 1;
        pageChange = page !== this.currentPage;
        this.currentPage = page;
      }

      if (limit) {
        limit = parseInt(limit, 10);
        if (Number.isNaN(limit) || !this.pageLimits.includes(limit))
          limit = this.pageLimits[0];
        this.limit = limit;
      }

      if (filter) {
        try {
          filter = this.$urlDecode(filter);
          this.filterQuery = JSON.parse(filter);
        } catch (e) {
          this.filterQuery = null;
          console.warn(e);
        }
      }

      if (sort) {
        const options = this.parseSortQueryToOptions(sort);
        this.options = Object.assign(this.options, options);
      }

      let query = {};
      if (this.currentPage) {
        query.page = this.currentPage;
      }
      if (this.limit) {
        query.limit = this.limit;
      }
      if (this.filterQuery) {
        query.filter = this.$urlEncode(JSON.stringify(this.filterQuery));
      }
      if (
        this.options.sortBy?.length > 0 &&
        this.options.sortDesc?.length > 0
      ) {
        query.sort = this.parseOptionsToSortQuery(this.options);
      }

      //update the URL query
      await this.$router.replace({ ...this.$route, query });

      if (
        namedRoute === "customers" ||
        (namedRoute === "customerDetail" &&
          (pageChange || !this.selectedCustomer))
      ) {
        //load customers either if the table page has changed or the customer is requested directly via URL
        //if a filter is set as query parameter, do not load it here, because the customers are already reloaded when changing the filter
        await this.loadCustomers();
      }

      if (namedRoute === "customerDetail") {
        //show customer detail of requested customer
        const customerId = this.$route.params.customer;

        if (!customerId) {
          this.closeEditor();
        } else if (
          !this.selectedCustomer ||
          customerId !== this.selectedCustomer.id
        ) {
          this.selectedCustomer =
            await this.$store.$coreApi.coreCustomerApi.getCustomer(
              this.selectedDomain,
              customerId
            );
          if (!this.selectedCustomer) this.closeEditor();
        }
      }
    },

    async loadCustomers() {
      let aborted = false;
      try {
        if (this.runningAction && this.loadCustomerController) {
          this.loadCustomerController.abort();
        }
        this.runningAction = true;
        //load current page of customers
        const offset =
          this.limit * (this.currentPage > 0 ? this.currentPage - 1 : 0);
        //build request body
        let body = {
          filter: {},
          sort: [],
          offset,
          limit: this.limit,
          total: true,
        };

        //set filters
        for (let index in this.filters) {
          const filter = this.filters[index];
          const property = filter.property;
          const value = filter.value;
          body.filter[property] = value;
        }

        //add sorting
        const sortBy = this.options?.sortBy;
        const sortDesc = this.options?.sortDesc;
        if (Array.isArray(sortBy)) {
          for (let i = 0; i < sortBy.length; i++) {
            body.sort.push({
              //parse property name to comply with the spec enums e.g. lastname => LASTNAME
              property: sortBy[i]
                .replace(/([a-z])([A-Z])/g, "$1_$2")
                .toUpperCase(),
              desc: sortDesc[i] ?? false,
            });
          }
        }

        const controller = new AbortController();
        const signal = controller.signal;
        this.loadCustomerController = controller;

        const res = await this.$store.$coreApi.coreCustomerApi.searchCustomers(
          this.selectedDomain,
          body,
          signal
        );

        if (res?.aborted) {
          aborted = true;
          this.loadCustomerController = null;
          return;
        }

        if (!res?.ok) return;
        const data = await res.json();
        this.customers = data.result;
        this.total = data.total;
      } finally {
        if (!aborted) this.runningAction = false;
      }
    },

    async createCustomer() {
      await this.closeEditor();
      this.selectedCustomer = {};
    },

    async editCustomer(customer) {
      if (!this.selectedCustomer || this.selectedCustomer.id !== customer.id) {
        await this.$router.push({
          name: "customerDetail",
          params: { customer: customer.id },
          query: this.$route.query,
        });
        this.selectedCustomer = this.$cloneObject(customer);
      }
    },

    async closeEditor(reload) {
      await this.$router.push({
        name: "customers",
        query: this.$route.query,
      });
      this.selectedCustomer = null;
      if (reload) {
        this.loadCustomers();
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    totalPages() {
      return Math.ceil(this.total / this.limit);
    },

    headers() {
      return [
        { text: "First Name", value: "firstname" },
        { text: "Last Name", value: "lastname" },
        { text: "E-Mail", value: "email" },
        { text: "Gender", value: "gender" },
        { text: "Date of Birth", value: "birthdate" },
        { text: "Type", value: "type" },
      ];
    },

    pageLimits() {
      return [20, 100, 200];
    },

    possibleFilters() {
      return [
        { text: "First Name", property: "firstname", type: "text" },
        { text: "Last Name", property: "lastname", type: "text" },
        { text: "E-Mail", property: "email", type: "text" },
        {
          text: "Customer Type",
          property: "type",
          type: "list",
          options: this.customerTypes,
        },
      ];
    },

    sortableFields() {
      return {
        firstname: true,
        lastname: true,
        email: true,
        gender: true,
        birthdate: true,
        type: true,
      };
    },

    showMinified() {
      return (
        (this.selectedCustomer && this.tableWidth <= 900) ||
        this.getPageWidth() <= 900
      );
    },

    isModeCreate() {
      return this.selectedCustomer && !this.selectedCustomer.id;
    },
  },
};
</script>

<style scoped>
* {
  text-align: left;
}

.customer-table-toolbar .extended-toolbar {
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0 12px 8px 12px;
}

.customer-limit-select {
  max-width: 70px;
  margin-right: 4px;
}

.customer-table {
  max-height: 100%;
  padding: 0;
}

.customer-table.v-data-table {
  display: flex;
  flex: 1 1 auto;
}

.customer-table.v-data-table::v-deep > .v-data-table__wrapper {
  width: 100%;
}

.customer-table.v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr {
  cursor: pointer;
}
</style>